const TabData01 = [
    {
        tabTitle: 'PLANNING',
        subtitle: 'MODERNIZED REDESIGN',
        tabicons: "",
        title: 'Business planning',
        img: 'https://via.placeholder.com/800x625',
        content: 'Lorem ipsum dolor sit amet consectetur adipiscing elit do eiusmod tempor incididunt ut labore et dolore magna ut enim ad minim veniam nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        buttonTitle: "Discover Litho",
        buttonLink: "/"
    },
    {
        tabTitle: 'RESEARCH',
        subtitle: 'INNOVATIVE SOLUTIONS',
        tabicons: "",
        title: 'Business research',
        img: 'https://via.placeholder.com/800x625',
        content: 'Lorem ipsum dolor sit amet consectetur adipiscing elit do eiusmod tempor incididunt ut labore et dolore magna ut enim ad minim veniam nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        buttonTitle: "Discover Litho",
        buttonLink: "/"
    },
    {
        tabTitle: 'TARGET',
        subtitle: 'MODERNIZED REDESIGN',
        tabicons: "",
        title: 'Business target',
        img: 'https://via.placeholder.com/800x625',
        content: 'Lorem ipsum dolor sit amet consectetur adipiscing elit do eiusmod tempor incididunt ut labore et dolore magna ut enim ad minim veniam nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        buttonTitle: "Discover Litho",
        buttonLink: "/"
    },
    {
        tabTitle: 'CAMPAIGN',
        subtitle: 'INNOVATIVE SOLUTIONS',
        tabicons: "",
        title: 'Business campaign',
        img: 'https://via.placeholder.com/800x625',
        content: 'Lorem ipsum dolor sit amet consectetur adipiscing elit do eiusmod tempor incididunt ut labore et dolore magna ut enim ad minim veniam nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        buttonTitle: "Discover Litho",
        buttonLink: "/"
    }
]

const WorkTypeData = [
    {
        stext: "Værker til salg",
        slink: "link"
    },
    {
        stext: "Nyeste Værker",
        slink: "link"
    },
    {
        stext: "Solgte",
        slink: "link"
    }   
]

const TabDataArtist = [
    {
        tabTitle: '',
        day: "Stilart"
    },
    {
        tabTitle: '',
        day: "Kategori"
    }, 
    {
        tabTitle: 'Om kunstneren',
        day: "Kunstnerprofil"
    }, {
        tabTitle: 'Videoer',
        day: "Kunstnerprofil"
    },
]

const WorkStylesData = [
    {
        stext: "Alle",
        slink: "link"
    },
    {
        stext: "Nordic Painting",
        slink: "link"
    },
    {
        stext: "Anden kategori",
        slink: "link"
    }   
]




export { TabData01, TabDataArtist, WorkTypeData, WorkStylesData }