const portfolioHabsoeData = [
    {
        title: "Atlantic Puffins",
        img: "https://habsoe.dk/wp-content/uploads/products/CL28139.jpg",
        category: ["nordic"],
        subtitle: "CL28139  -  100 x 120 cm.  -  kr. 44.000,-",
        link: "/portfolio/single-project-page-01"
    },
    {
        title: "Hicking",
        img: "https://habsoe.dk/wp-content/uploads/products/CL28135-1.jpg",
        category: ["nordic"],
        subtitle: "CL28135 - 100 x 150 cm.  -  kr. 50.000,-",
        link: "/portfolio/single-project-page-02"
    },
    {
        title: "(Motor)Save The Jungle",
        img: "https://habsoe.dk/wp-content/uploads/products/CL28136.jpg",
        category: ["kategori1"],
        subtitle: "CL28136  -  150 x 200 cm.  -  kr. 164.350,-",
        link: "/portfolio/single-project-page-01"
    },
    {
        title: "Fata Morgana",
        img: "https://habsoe.dk/wp-content/uploads/products/CL281371.jpg",
        category: ["kategori1"],
        subtitle: "CL28137  -  90 x 130 cm.  -  kr. 103.125,-",
        link: "/portfolio/single-project-page-01"
    },
    {
        title: "Tid er guld værd",
        img: "https://habsoe.dk/wp-content/uploads/products/CL28131.jpg",
        category: ["kategori1"],
        subtitle: "CL28131  -  90 x 140 cm.  -  kr. 107.800,-",
        link: "/portfolio/single-project-page-01"
    },        
    {
        title: "Linedanser på falderebet",
        img: "https://habsoe.dk/wp-content/uploads/products/CL28127.jpg",
        category: ["kategori1"],
        subtitle: "CL28127  -  90 x 130 cm.  -  kr. 103.125,-",
        link: "/portfolio/single-project-page-01"
    }, 
    {
        title: "Bæredygtig ferie",
        img: "https://habsoe.dk/wp-content/uploads/products/CL28122.jpg",
        category: ["kategori1"],
        subtitle: "CL28122  -  90 x 130 cm.  -  kr. 103.125,-",
        link: "/portfolio/single-project-page-01"
    }, 
    {
        title: "Golden Lady With Penguin",
        img: "https://habsoe.dk/wp-content/uploads/products/CL28125.jpg",
        category: ["kategori1"],
        subtitle: "CL28125  -  110 x 80 cm.  -  kr. 89.000,-",
        link: "/portfolio/single-project-page-01"
    }, 
    {
        title: "Til lands til vands og i luften",
        img: "https://habsoe.dk/wp-content/uploads/products/CL28120.jpg",
        category: ["kategori1"],
        subtitle: "CL28120  -  100 x 140 cm.  -  kr. 112.500,-",
        link: "/portfolio/single-project-page-01"
    },
]

const portfolioBoxedData = [
    {
        title: "Atlantic Puffins",
        img: "https://habsoe.dk/wp-content/uploads/products/CL28139.jpg",
        category: ["nordic"],
        subtitle: "CL28139  -  100 x 120 cm.  -  kr. 44.000,-",
        link: "/portfolio/single-project-page-01"
    },
    {
        title: "Hicking",
        img: "https://habsoe.dk/wp-content/uploads/products/CL28135-1.jpg",
        category: ["nordic"],
        subtitle: "CL28135 - 100 x 150 cm.  -  kr. 50.000,-",
        link: "/portfolio/single-project-page-02"
    },
    {
        title: "(Motor)Save The Jungle",
        img: "https://habsoe.dk/wp-content/uploads/products/CL28136.jpg",
        category: ["kategori1"],
        subtitle: "CL28136  -  150 x 200 cm.  -  kr. 164.350,-",
        link: "/portfolio/single-project-page-01"
    },
    {
        title: "Fata Morgana",
        img: "http://habsoe.dk.nt36.unoeuro-server.com/files/MKC/img/vaerk/vaerk800/CL28137.jpg",
        category: ["kategori1"],
        subtitle: "CL28137  -  90 x 130 cm.  -  kr. 103.125,-",
        link: "/portfolio/single-project-page-01"
    },
    {
        title: "Tid er guld værd",
        img: "http://habsoe.dk.nt36.unoeuro-server.com/files/MKC/img/vaerk/vaerk800/CL28131.jpg",
        category: ["kategori1"],
        subtitle: "CL28131  -  90 x 140 cm.  -  kr. 107.800,-",
        link: "/portfolio/single-project-page-01"
    },        
    {
        title: "Linedanser på falderebet",
        img: "http://habsoe.dk.nt36.unoeuro-server.com/files/MKC/img/vaerk/vaerk800/CL28127.jpg",
        category: ["kategori1"],
        subtitle: "CL28127  -  90 x 130 cm.  -  kr. 103.125,-",
        link: "/portfolio/single-project-page-01"
    }, 
    {
        title: "Bæredygtig ferie",
        img: "http://habsoe.dk.nt36.unoeuro-server.com/files/MKC/img/vaerk/vaerk800/CL28122.jpg",
        category: ["kategori1"],
        subtitle: "CL28122  -  90 x 130 cm.  -  kr. 103.125,-",
        link: "/portfolio/single-project-page-01"
    }, 
    {
        title: "Golden Lady With Penguin",
        img: "http://habsoe.dk.nt36.unoeuro-server.com/files/MKC/img/vaerk/vaerk800/CL28125.jpg",
        category: ["kategori1"],
        subtitle: "CL28125  -  110 x 80 cm.  -  kr. 89.000,-",
        link: "/portfolio/single-project-page-01"
    }, 
    {
        title: "Til lands til vands og i luften",
        img: "http://habsoe.dk.nt36.unoeuro-server.com/files/MKC/img/vaerk/vaerk800/CL28120.jpg",
        category: ["kategori1"],
        subtitle: "CL28120  -  100 x 140 cm.  -  kr. 112.500,-",
        link: "/portfolio/single-project-page-01"
    },
]

const FilterData = [
    {
        title: "All",
        key: "*"
    },
    {
        title: "NORDIC PAINTING",
        key: "nordic"
    },
    {
        title: "KATEGORI1",
        key: "kategori1"
    }
]

const landingPageFilterData = [
    {
        title: "All",
        key: "*"
    },
    {
        title: "CORPORATE",
        key: "corporate"
    },
    {
        title: "SPECIALIZED",
        key: "specialized"
    },
    {
        title: "PORTFOLIO",
        key: "portfolio"
    },
    {
        title: "E-COMMERCE",
        key: "e-commerce"
    },
    {
        title: "BLOG",
        key: "blog"
    }
]

export {
    portfolioHabsoeData, portfolioBoxedData, FilterData, landingPageFilterData
};