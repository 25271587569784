import React, { memo } from 'react'

// Libraries
import { Col, Nav, Row, Tab } from 'react-bootstrap'
import { PropTypes } from "prop-types";

// Data
import { TabDataArtist } from './TabData'
import { data } from 'isotope-layout';

import KategoriSelect from './kategorier'
import StilSelect from './workstyles'



const ArtistMenu = (props) => {
    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
            <Row className="tab-style-06 justify-center">
                <Col lg={12}>
                    <Nav className="justify-center">
                        {
                            props.data.map((item, i) => {
                                return (
                                    <Nav.Item key={i}>
                                        <Nav.Link eventKey={i} data-filter="nordic" className="border-transparent px-[20px] mx-[20px] text-orange-300 font-serif text-center m-0 font-medium cursor-pointer md:top-0 md:py-[40px] md:pb-[22px] lg:pt-0 lg:px-[40px] lg:pb-[22px] md:pt-0 md:px-[20px] sm:py-[15px] sm:px-[20px]">
                                            {item.day && <span className="block font-serif text-center font-normal text-gray-300">{item.day}</span>}
                                             {
                                             (item.day == "Stilart") &&
                                               <StilSelect />
                                             ||
                                             (item.day == "Kategori") &&
                                                <KategoriSelect />
                                            ||
                                            item.tabTitle
                                            }
                                        </Nav.Link>
                                    </Nav.Item>
                                )
                            })
                        }
                    </Nav>
                </Col>
            </Row>
        </Tab.Container>
    )
}

ArtistMenu.defaultProps = {
    data: TabDataArtist
}

ArtistMenu.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.exact({
            tabTitle: PropTypes.string,
            subtitle: PropTypes.string,
            selector2: PropTypes.string,
            title: PropTypes.string,
            img: PropTypes.string,
            content: PropTypes.string,
            buttonLink: PropTypes.string,
            buttonTitle: PropTypes.string,
            day: PropTypes.string,
            activities: PropTypes.arrayOf(
                PropTypes.exact({
                    time: PropTypes.string,
                    content: PropTypes.string,
                    title: PropTypes.string,
                    img: PropTypes.string,
                    author: PropTypes.string,
                }))
        })
    ),
}

// const Filter = (props) 

// Filter.propTypes = {
//     title: PropTypes.oneOfType([
//         PropTypes.string,
//         PropTypes.object
//     ]),
//     filterData: PropTypes.arrayOf(
//         PropTypes.exact({
//             key: PropTypes.string,
//             title: PropTypes.string,
//         })
//     ),
// }


export default memo(ArtistMenu)