const HeaderData = [
    {
        title: 'Kunstnere',
        megamenu: [
            {
                title: 'Kunstnere',
                dropdown: [
                    {
                        title: 'Alle',
                        link: '/home-startup'
                    },
                    {
                        title: '10 nyeste',
                        link: '/home-business'
                    }
                ],
            },
            {
                title: 'Værker',
                dropdown: [
                    {
                        title: '300 Nyeste',
                        link: '/home-restaurant',
                    },
                    {
                        title: 'Særudstilling',
                        link: '/home-architecture',
                    }
                ],
            },
        ]
    },
    {
        title: 'Kunstcenteret',
        dropdown: [
            {
                title: 'Påskeudstilling',
                link: '/blogs/blog-grid'
            },
            {
                title: 'Bliv medlem',
                dropdown: [
                    {
                        title: 'Bliv medlem med fordel',
                        link: '/blogs/blog-grid'
                    },
                    {
                        title: 'Vind et gavekort',
                        link: '/blogs/blog-grid'
                    }
                ]
            },
            {
                title: 'Blog masonry',
                link: '/blogs/blog-masonry'
            },
            {
                title: 'Blog classic',
                link: '/blogs/blog-classic'
            }
      ]
    },
    {
        title: "Erhverv",
        dropdown: [
            {
                title: 'Kontakt',
                link: '/blogs/blog-grid'
            }
         ]
    },
    {
        title: "Auktioner",
        dropdown: [
            {
                title: 'Kontakt',
                link: '/blogs/blog-grid'
            }
         ]
    },
    {
        title: "Grafiske værker",
        dropdown: [
            {
                title: 'Kontakt',
                link: '/blogs/blog-grid'
            }
         ]
    },
    {
        title: "Kontakt",
        dropdown: [
            {
                title: 'Kontakt',
                link: '/blogs/blog-grid'
            }
         ]
    }
]

export default HeaderData