import React, { memo } from 'react'

// Libraries
import { Col, Nav, Row, Tab } from 'react-bootstrap'
import { PropTypes } from "prop-types";

// Data
import { WorkStylesData } from './TabData'
import { data } from 'isotope-layout';
import { propTypes } from 'react-bootstrap/esm/Image';

const WorkStylesDropDown = (props) => {
  return (

   <select className='border-b-[3px] border-solid border-transparent px-[20px] mx-[20px] text-orange-300 bg-transparent font-serif text-center m-0 font-semibold cursor-pointer md:top-0 md:py-[40px] md:pb-[22px] lg:pt-0 lg:px-[40px] lg:pb-[22px] md:pt-0 md:px-[20px] sm:py-[15px] sm:px-[20px]' id ="test">
     
    {
      props.data.map((item, i) => {
        return (
            <option>{ item.stext }</option>
        )
      })
    }
    )
  </select>
  )
}


WorkStylesDropDown.defaultProps = {
    data: WorkStylesData
}

WorkStylesDropDown.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.exact({
            stext: propTypes.string,
            slink: propTypes.string
        })
    )
}

export default memo(WorkStylesDropDown)